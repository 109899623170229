import React from 'react';
import PropTypes from 'prop-types';
import SearchPage from '../components/Views/SearchPage';
import {
  getDataForTransformationsQuery,
  searchTotalCountQuery
} from '../queries';
import {
  SearchItemsTitleService,
  fromUrlToObj,
  getSeoData,
  getValueFromObject,
  adaptFiltersForState
} from '../utils/helpers';
import i18n from '../utils/i18n';
import Layout from '../components/Layout/Layout';
import {
  setChosenLocationNames,
  setCurrentState,
  setLocationIds,
  setSearchSort
} from '../actions';

const Search = ({ transformedObj, totalAdsCount }) => {
  return (
    <SearchPage transformedObj={transformedObj} totalAdsCount={totalAdsCount} />
  );
};

Search.getInitialProps = async ({
  apolloClient,
  asPath,
  query,
  res,
  req,
  store
}) => {
  const dataForTransform = await apolloClient.query({
    query: getDataForTransformationsQuery
  });
  const transformedObj = fromUrlToObj(dataForTransform, query);

  if (res && transformedObj.noMatch) {
    res.statusCode = 404;

    return {
      statusCode: 404
    };
  }
  const locationsIds = transformedObj?.filter?.locationIds || [];
  const storedFormState = store.getState().searchForm.currentState;
  const objForStore = adaptFiltersForState(transformedObj?.filter);
  store.dispatch(setCurrentState({ ...storedFormState, ...objForStore }));
  // eslint-disable-next-line
  const { category_touched, ...filtersWithoutCategoryTouched } = transformedObj.filter;
  transformedObj.filter = filtersWithoutCategoryTouched;
  const totalAdsCountData = await apolloClient.query({
    query: searchTotalCountQuery(filtersWithoutCategoryTouched),
    variables: {
      filter: filtersWithoutCategoryTouched
    }
  });

  store.dispatch(
    setSearchSort(
      getValueFromObject(
        dataForTransform.data.sort,
        transformedObj.sorting,
        'id'
      ) || '1'
    )
  );
  store.dispatch(
    setChosenLocationNames(
      dataForTransform.data.locations
        .filter(location => locationsIds.includes(location.id))
        .map(location => location.name.trim())
    )
  );
  store.dispatch(
    setLocationIds(
      dataForTransform.data.locations
        .filter(location => locationsIds.includes(location.id))
        .map(location => location.id)
    )
  );

  const seoData = await getSeoData(apolloClient, asPath, req);

  if (!seoData.title || !seoData.description) {
    seoData.title = new SearchItemsTitleService(
      dataForTransform.data,
      transformedObj.filter,
      i18n
    ).getTitle();

    seoData.description = i18n.t('default_search_description');
  }

  return {
    transformedObj,
    seoData,
    totalAdsCount: totalAdsCountData?.data?.itemsConnection?.totalCount
  };
};

Search.propTypes = {
  transformedObj: PropTypes.object,
  totalAdsCount: PropTypes.number
};

Search.getLayout = page => <Layout isShowHeader={false}>{page}</Layout>;

export default Search;
