import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import FallbackView from '../../FallbackView';
import {
  setChosenLocationNames,
  setCurrentCity,
  setCurrentState,
  setError,
  setLocationIds
} from '../../../actions';
import NewSearchPageResults from '../../NewSearch/NewSearchPageResults';

const mapDispatchToProps = dispatch => {
  return {
    setErrorUI: errorState => dispatch(setError(errorState))
  };
};

const SearchPage = ({ transformedObj, setErrorUI, totalAdsCount }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formEl = useRef(null);
  const resetForm = () => {
    const resetData = {
      city_id: '1',
      leased: 'false',
      category_id: '1'
    };
    formEl?.current?.reset(resetData);
    dispatch(setChosenLocationNames([]));
    dispatch(setLocationIds([]));
    dispatch(setCurrentCity('1'));
    dispatch(
      setCurrentState({
        ...resetData,
        category_touched: false
      })
    );
  };
  const emptyLayout = (
    <FallbackView
      width={228}
      height={280}
      isShowHeader={true}
      onClickHandler={resetForm}
      text={t('search.no_results.text')}
      title={t('search.nothing_found')}
      btnText={t('search.no_results.link')}
      icon="/static/images/new_search/icons/no-results-icon.png"
    />
  );

  if (totalAdsCount === 0) {
    return emptyLayout;
  }
  return (
    <NewSearchPageResults {...{ transformedObj, setErrorUI }} ref={formEl} />
  );
};

SearchPage.propTypes = {
  transformedObj: PropTypes.object,
  totalAdsCount: PropTypes.number,
  setErrorUI: PropTypes.func
};

export default connect(null, mapDispatchToProps)(SearchPage);
